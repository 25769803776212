// extracted by mini-css-extract-plugin
export var Filename = "complaints-module--Filename--4nRR-";
export var block = "complaints-module--block--mulUc";
export var button = "complaints-module--button--+UbhT";
export var complaints = "complaints-module--complaints--pc76u";
export var complaintsMain = "complaints-module--complaintsMain--Xb31t";
export var content = "complaints-module--content--ojGDj";
export var contentTitle = "complaints-module--contentTitle--wzq+w";
export var deleteGrey = "complaints-module--deleteGrey--4Fr7x";
export var downImg = "complaints-module--downImg--O1jol";
export var errorDialog = "complaints-module--errorDialog--U8flQ";
export var file = "complaints-module--file--Zoo7P";
export var fileIcon = "complaints-module--fileIcon--+dura";
export var form = "complaints-module--form--FEvZH";
export var grey = "complaints-module--grey--kvnjT";
export var icon = "complaints-module--icon--tSKMb";
export var input = "complaints-module--input--yC6zq";
export var inputArea = "complaints-module--inputArea--Kcr+y";
export var inputAreaCenter = "complaints-module--inputAreaCenter--nv00h";
export var inputError = "complaints-module--inputError--k3IAO";
export var inputErrorContent = "complaints-module--inputErrorContent--48lDz";
export var inputFile = "complaints-module--inputFile--Gx5rD";
export var inputNumber = "complaints-module--inputNumber--BwFzv";
export var inputUpload = "complaints-module--inputUpload--UrOkX";
export var inputWarn = "complaints-module--inputWarn--lY4I6";
export var inputWarnGrey = "complaints-module--inputWarnGrey--LrNyP";
export var inputWarnText = "complaints-module--inputWarnText--eM0km";
export var mobileSeedcredLink = "complaints-module--mobileSeedcredLink--qqM5Y";
export var name = "complaints-module--name--m1U+2";
export var none = "complaints-module--none--WkZK+";
export var request = "complaints-module--request--vla9B";
export var success = "complaints-module--success--L7p4K";
export var successBg = "complaints-module--successBg--EZk15";
export var successBtn = "complaints-module--successBtn--Nosig";
export var successText = "complaints-module--successText--5qQCj";
export var successTitle = "complaints-module--successTitle--gr12K";
export var successimg = "complaints-module--successimg--rmKuk";
export var titleOne = "complaints-module--titleOne--j9h64";
export var titleTwo = "complaints-module--titleTwo--PQdMx";
export var topInfo = "complaints-module--topInfo--0OUkO";
export var topLeft = "complaints-module--topLeft--PLpOP";
export var topRight = "complaints-module--topRight--ZWVue";
export var upload = "complaints-module--upload--2yzaq";
export var uploaded = "complaints-module--uploaded--Il12h";
export var warn = "complaints-module--warn--Alcd7";