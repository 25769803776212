import * as React from 'react'
import 'element-theme-default';
import * as styles from './css/complaints.module.styl'
import Layout from "../components/layout";
import Seo from "../components/seo";
import { widthInfo, encrypt, genIdWithPhoneNumAndDateTime } from "../util/index.js"
import { graphql } from "gatsby";
import { Trans } from 'gatsby-plugin-react-i18next';

const width = widthInfo()
class Complaints extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    // this.inputfileFun = this.inputfileFun.bind(this);
  }
  get initialState() {
    return {
      name: '',
      mobile: '',
      loan_id: '',
      file: null,
      success: false,
      error: false,
      errorInfo: '',
      Textnumber: 0,

      validata: {
        name: true,
        mobile: true,
        loan_id: true
      },
      hasFile: false
    }
  }
  get canClick() {
    return this.state.name && this.state.mobile
  }
  changeData = (e) => {//input输入什么，就监听这个方法，然后再修改state，然后返回到视图
    let data = this.state.validata

    if (e.target.value || (!e.target.value && e.target.name === 'loan_id')) {
      data[e.target.name] = true
    } else {
      data[e.target.name] = false
    }
    this.setState({
      [e.target.name]: e.target.value,
      validata: data
    })
  }
  validataFun(e) {
    let info = {
      name: this.state.name,
      mobile: this.state.mobile
    }
    let flag
    let validataInfo = this.state.validata
    let MAtch = /^(03)\d{9}$/
    let matchTwo = /^(923)\d{9}$/
    for (let item in info) {
      if (!info[item]) {
        flag = true
        validataInfo[item] = false
      }
      if (item === 'mobile') {
        if (MAtch.test(info[item]) || matchTwo.test(info[item])) {
          validataInfo.mobile = true
        } else {
          validataInfo.mobile = false
          flag = true
        }
      }
    }
    if (this.state.loan_id.toString().length > 18 || this.state.loan_id < 0) {
      validataInfo.loan_id = false
      flag = true
    } else {
      validataInfo.loan_id = true
    }

    this.setState({
      validata: validataInfo
    })
    if (flag) {
      if (width <= 1024) {
        window.scrollTo(0, 300);
      } else {
        window.scrollTo(0, 569);
      }
      return
    } else {
      this.setState(this.initialState)
      // this.postFun()
      this.goLiveChat()
    }
  }
  goLiveChat () {
    const url = this.generateLiveChatUrl()
    window.open(url, '_blank')
  }

  generateLiveChatUrl() {
    const liveChatUrl = 'https://cscenter-pakistan.barwaqtfintec.com/chat/pc/v2/index.html?sysnum=cb16857eb9204a80b07f3c5c89f97175&channelid=5&channelFlag=5'
    // const liveChatUrl = 'https://h5-zc-mp.opayweb.com/chat/pc/v2/index.html?sysnum=90f50356ca17449fa17485c5fe5c8f3c&channelid=2&channelFlag=2'
    // const devLiveChatUrl =
    //   'https://cstest-pakistan.barwaqtfintec.com/chat/pc/v2/index.html?sysnum=0cde476161e04fc8997714a938cce90c&channelid=2'
    // const url = !location.host.includes('test')
    //   ? devLiveChatUrl
    //   : prodLiveChatUrl
    const { name, mobile, loan_id } = this.state
    const userInfo = encrypt({
      uuid: genIdWithPhoneNumAndDateTime(mobile),
      partnerid: genIdWithPhoneNumAndDateTime(mobile),
      uname: name,
      realname: name,
      tel: mobile,
      userType: 6,
      params: {
        loanId: loan_id,
      },
      timestamp: Date.now(),
      source: 0, // 0 代表 PC 端
    })
    return `${liveChatUrl}&data=${userInfo}`
  }
  // inputfileFun({ target }) {
  //   if (target.files[0] && target.files[0].type !== 'application/pdf' && target.files[0].type !== 'image/png' && target.files[0].type !== 'image/jpg' && target.files[0].type !== 'image/jpeg' && target.files[0].type !== 'image/jfif') {
  //     let that = this
  //     target.value = ''
  //     this.setState({
  //       error: true,
  //       errorInfo: 'Please upload valid file type',
  //       file: null
  //     })
  //     setTimeout(() => {
  //       that.setState({
  //         error: false,

  //       })
  //     }, 2000)
  //     return
  //   }
  //   if (target.files[0] && target.files[0].size > 5 * 1024 * 1024) {
  //     let that = this
  //     target.value = ''
  //     this.setState({
  //       error: true,
  //       errorInfo: 'Please upload the file less than 5M',
  //       file: null
  //     })
  //     setTimeout(() => {
  //       that.setState({
  //         error: false
  //       })
  //     }, 2000)

  //     return
  //   }
  //   this.setState({
  //     file: target.files[0],
  //     hasFile: true
  //   });
  // }
  // deleteFile = () => {
  //   this.setState({
  //     file: null,
  //     hasFile: false
  //   });
  // }
  // postFun = () => {
  //   const formData = new FormData();
  //   formData.append('fs', this.state.file);
  //   formData.append('name', this.state.name);
  //   formData.append('mobile', this.state.mobile);
  //   formData.append('loan_id', this.state.loan_id);

  //   const isTest = window.location.href.indexOf('test') > -1

  //   const host = isTest ? 'https://api-barwaqt-pk-test.barwaqtfintec.com' : 'https://api.barwaqtfintec.com'
  //   const api = '/api/v1/web/feedback'

  //   fetch(
  //     host + api,
  //     {
  //       method: 'post',
  //       body: formData,
  //     })
  //     .then(results => {
  //       return results.json();
  //     })
  //     .then(data => {
  //       if (data.code === 0) {
  //         // let that = this
  //         this.setState(this.initialState);
  //         this.stopScroll()
  //         this.setState({
  //           success: true,
  //           hasFile: false
  //         })

  //       } else {
  //         let that = this
  //         this.setState({
  //           error: true,
  //           errorInfo: data.message
  //         })

  //         setTimeout(() => {
  //           that.setState({
  //             error: false
  //           })
  //         }, 3000)
  //       }
  //     })
  //     .catch(err => {
  //       this.setState({
  //         error: true,
  //         errorInfo: err.message
  //       })

  //       setTimeout(() => {
  //         this.setState({
  //           error: false
  //         })
  //       }, 3000)
  //     })
  // }
  // closeFun() {
  //   this.canScroll()
  //   this.setState({
  //     success: false,
  //   })
  // }
  // stopScroll() {
  //   let _scrollTop =
  //     document.documentElement.scrollTop ||
  //     window.pageYOffset ||
  //     document.body.scrollTop;
  //   document.body.classList.add("dialog");
  //   document.body.style.top = -_scrollTop + "px";
  // }
  // canScroll() {
  //   document.body.classList.remove("dialog");
  //   // scrollTop lost after set position:fixed, restore it back.
  //   document.documentElement.scrollTop = this.scrollTop;
  //   window.pageYOffset = this.scrollTop;
  //   document.body.scrollTop = this.scrollTop;
  // }
  render() {
    return (
      <Layout>
        <Seo title="Complaints" />
        <div className={styles.complaints}>
          <div className={styles.topInfo}>
            <div className={styles.topLeft}>
              <div className={styles.titleOne}>
                <Trans i18nKey="banner.title" />
              </div>
              <div className={styles.titleTwo}>
                <Trans i18nKey="banner.subtitle.para" />
                {/* {width > 1024 ? (
                  <Trans i18nKey="banner.subtitle.para" />
                ) : (
                  <>
                    <Trans i18nKey="banner.subtitle.line1" />
                    <br />
                    <Trans i18nKey="banner.subtitle.line2" />
                  </>
                )} */}
              </div>
              {/* href="https://play.google.com/store/apps/details?id=com.loan.cash.credit.barwaqt.tez.paisa.jazz.financial.ready.easy" */}
              <a aria-label="index download" 
              download
              href="https://www.barwaqtfintec.com/Pakistan_2.0.0.3048_googleplay_104488.apk"
              ><div className={styles.downImg}></div></a>
              {
                width < 750 ? (
                  <a aria-label="index download" className={styles.mobileSeedcredLink} target="_blank" href="https://www.seedcredfintec.com/"></a>
                ) : null
              }
            </div>
            <div className={styles.topRight}></div>
          </div>
          <section className={styles.complaintsMain}>
            <div className={styles.content}>
              <div className={styles.contentTitle}>
                <Trans i18nKey="form.title" />
              </div>
              <div className={styles.form}>
                <div>
                  <ul className={styles.input}>
                    <li className={styles.name}> <i className={styles.request}></i> <span><Trans i18nKey="form.name"/></span> </li>
                    <li >
                      <input ref="name" value={this.state.name} name="name" type="text" onChange={(e) => this.changeData(e)} ></input>
                    </li>

                    <li className={styles.inputError}>
                      {this.state.validata.name ? '' : <div className={styles.inputErrorContent}><i className={styles.warn}></i><span><Trans i18nKey="form.errorMsg.name"/></span></div>}
                    </li>
                  </ul>
                  <ul className={styles.input}>
                    <li className={styles.name}> <i className={styles.request}></i> <span><Trans i18nKey="form.mobile" /></span> </li>
                    <li  >
                      <input maxLength="12" name="mobile" value={this.state.mobile} onChange={(e) => this.changeData(e)} type="number"></input>
                    </li>
                    <li className={styles.inputError}>
                      {this.state.validata.mobile ? '' : <div className={styles.inputErrorContent}>  <i className={styles.warn}></i><span><Trans i18nKey="form.errorMsg.mobile"/></span></div>}
                    </li>
                  </ul>
                  <ul className={styles.input}>
                    <li className={styles.name}> <span><Trans i18nKey="form.loanId" /></span> </li>
                    <li  >
                      <input min="0" value={this.state.loan_id} name="loan_id" type="number" onChange={(e) => this.changeData(e)}></input>
                    </li>
                    <li className={styles.inputError}>
                      {this.state.validata.loan_id ? '' : <li className={styles.inputErrorContent}> <i className={styles.warn}></i><span><Trans i18nKey="form.errorMsg.loanId"/></span></li>}
                    </li>
                  </ul>
                </div>
                {/* <div>
                  <ul className={styles.input}>
                    <li className={styles.name}> <span><Trans i18nKey="form.uploader.title"/></span> </li>
                    <li className={styles.inputUpload} >

                      {!this.state.hasFile ?
                        <>
                          <input className={`${styles.inputFile} `} type="file"
                            accept="image/*,application/pdf" id="file" name="file" onChange={this.inputfileFun}  ></input>
                          <div className={styles.upload} >
                            <span className={styles.icon}></span> <span><Trans i18nKey="form.uploader.desc"/></span>
                          </div>
                        </>
                        :
                        <>
                          <div className={`${styles.inputFile} `}></div>
                          <div className={styles.uploaded} >
                            <div className={styles.file}><span className={styles.fileIcon}></span> <span className={styles.Filename}>{this.state.file ? this.state.file.name : ''}</span></div>
                            <div className={styles.deleteGrey} onClick={this.deleteFile}></div>
                          </div>
                        </>
                      }
                      <div className={`${styles.inputError} ${styles.inputWarn}`}> <i className={styles.inputWarnGrey}></i><span className={styles.inputWarnText}><Trans i18nKey="form.uploader.tip"/></span></div>
                    </li>
                  </ul>
                </div> */}

              </div>
              {this.canClick ?
                <div className={`${styles.button} `} onClick={(e) => this.validataFun()}><Trans i18nKey="form.submitBtn"/></div> :
                <div className={`${styles.button} ${styles.grey}`}><Trans i18nKey="form.submitBtn"/></div>
              }
            </div>
          </section>
        </div>

        {/* <div className={`${styles.successBg} ${this.state.success ? 'block' : 'none'}`}>
          <ul className={`${styles.success} ${this.state.success ? 'block' : 'none'}`}>
            <li className={styles.successimg}   ></li>
            <li className={styles.successTitle}>
              <Trans i18nKey="form.submitSuccessMsg.status"/>
            </li>
            <li className={styles.successText}>
              <Trans i18nKey="form.submitSuccessMsg.desc"/>
            </li>
            <li className={styles.successBtn} onClick={(e) => this.closeFun()}>
              <Trans i18nKey="form.submitSuccessMsg.btnName"/>
            </li>
          </ul>
        </div> */}

        {/* <div className={`${styles.successBg} ${this.state.error ? 'block' : 'none'}`}>
          <div className={`${styles.errorDialog} `}>
            {this.state.errorInfo}
          </div>
        </div> */}
      </Layout>
    )
  }
}

export default Complaints;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["complaints", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
